import getLocalStorage from './getLocalStorage';

const setLocalStorage = (key, value) => {
  const storage = getLocalStorage();
  if (key) {
    storage[key] = value;
    localStorage.setItem(`qweri-module-rgpd-v${process?.env?.REACT_APP_VERSION}`, JSON.stringify(storage));
  }
};

export default setLocalStorage;
