/* eslint-disable max-len */
export const formsNames = {
  1: 'Chat & Chatbot',
  2: 'Inscription à un service de santé (Suivi IMC, appli régime, carnet alimentaire...)',
  3: 'Téléchargements (livres blancs, documentation...)',
  4: "Collecte de CV dans le cadre d'un recrutement",
  5: 'Jeu concours',
  6: 'Test (Personnalité, quizz)',
  7: "Réservation d'un service (massage, esthéticienne, barbier...)",
  8: "Réservation d'un moyen de transport individuel (voiture, utilitaire...)",
  9: 'Réservation de logement (hôtel, logement particulier...)',
  10: 'Click & Collect',
  11: 'Dépôt de dossiers de Location / Appartement',
  12: "Inscription à l'alerte de disponibilité d'un produit sur un site e-commerce",
  13: "Chargement d'un média/site (accès à un contenu restreint à certains publics, relatifs au sexe, à l'alcool et aux armes à feu)",
  14: 'Demande de devis (pose de fenêtres, déménagement...)',
  15: 'Formulaire de contact "simple"',
  16: "Achat d'un produit en ligne impliquant une livraison à domicile",
  17: 'Inscription à un programme de fidélité',
  18: 'Achat d’un produit dématérialisé',
  19: 'Évaluation suite à un achat',
};

export const formsB2B = [3, 14, 15];
export const formsB2C = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
