import form1 from '../constants/forms/form1.json';
import form2 from '../constants/forms/form2.json';
import form3 from '../constants/forms/form3.json';
import form4 from '../constants/forms/form4.json';
import form5 from '../constants/forms/form5.json';
import form6 from '../constants/forms/form6.json';
import form7 from '../constants/forms/form7.json';
import form8 from '../constants/forms/form8.json';
import form9 from '../constants/forms/form9.json';
import form10 from '../constants/forms/form10.json';
import form11 from '../constants/forms/form11.json';
import form12 from '../constants/forms/form12.json';
import form13 from '../constants/forms/form13.json';
import form14 from '../constants/forms/form14.json';
import form15 from '../constants/forms/form15.json';
import form16 from '../constants/forms/form16.json';
import form17 from '../constants/forms/form17.json';
import form18 from '../constants/forms/form18.json';
import form19 from '../constants/forms/form19.json';

const getFormQuestions = (formId) => {
  switch (formId) {
    case 1:
      return form1.questions;
    case 2:
      return form2.questions;
    case 3:
      return form3.questions;
    case 4:
      return form4.questions;
    case 5:
      return form5.questions;
    case 6:
      return form6.questions;
    case 7:
      return form7.questions;
    case 8:
      return form8.questions;
    case 9:
      return form9.questions;
    case 10:
      return form10.questions;
    case 11:
      return form11.questions;
    case 12:
      return form12.questions;
    case 13:
      return form13.questions;
    case 14:
      return form14.questions;
    case 15:
      return form15.questions;
    case 16:
      return form16.questions;
    case 17:
      return form17.questions;
    case 18:
      return form18.questions;
    case 19:
      return form19.questions;
    default:
      return null;
  }
};

export default getFormQuestions;
