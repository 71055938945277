/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { useHistory } from 'react-router-dom';
import { isNumber } from 'lodash';
import ReactTooltip from 'react-tooltip';
import getFormQuestions from '../../utils/getFormQuestions';
import getQweritips from '../../utils/getQweritips';

// Components
import Button from '../../components/atoms/Button/Button';
import ProgressBar from '../../components/molecules/ProgressBar/ProgressBar';
import FormCheckbox from '../../components/atoms/FormCheckbox/FormCheckbox';
import Modal from '../../components/organisms/Modal/Modal';

// Services
import editScore from '../../services/editScore';
import getNextQuestionWithTransition from '../../utils/getNextQuestionWithTransition';
import setProgression from '../../services/setProgression';
import getProgression from '../../services/getProgression';
import storeResults from '../../services/storeResults';

// Constants
import { formsNames } from '../../constants/formsNames';

const FormQuestionnaire = ({ currentForm, setcurrentForm }) => {
  const history = useHistory();

  const [currentQuestion, setCurrentQuestion] = useState(getProgression('currentQuestion'));
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const [checkedAnswers, setCheckedAnswers] = useState([]);

  const [formIsPerfect, setFormIsPerfect] = useState(false);
  const [formIsWrong, setFormIsWrong] = useState(false);

  const [scoreAfterCheck, setScoreAfterCheck] = useState(0);

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warnQuestion, setWarnQuestion] = useState(null);

  const [wantAnotherFormModal, setWantAnotherFormModal] = useState(false);

  useEffect(() => {
    setProgression(2, currentQuestion);
    ReactTooltip.rebuild();
  }, [currentQuestion]);

  const handleSelectAnswer = (answer) => {
    setSelectedAnswer(answer);

    const nextButton = document.getElementById('nextQuestionButton');
    if (nextButton) {
      window.scrollTo({
        top: nextButton.getBoundingClientRect().top + nextButton.offsetHeight + 100,
        behavior: 'smooth',
      });
    }
  };

  const resetScreen = () => {
    setSelectedAnswer(null);
    setCheckedAnswers([]);
    setFormIsPerfect(false);
    setFormIsWrong(false);
    document.body.classList.remove('bg-wrong');
    document.body.classList.remove('bg-perfect');
    setProgression(2, 1);
  };

  const handleNextQuestion = (score) => {
    editScore(
      2,
      isNumber(score) ? score : getFormQuestions(currentForm)[currentQuestion].answers[selectedAnswer + 1].score,
    );

    const checkedAnswersLabels = checkedAnswers.length > 0 ? checkedAnswers.map(
      (answ) => getFormQuestions(currentForm)[currentQuestion].answers[answ + 1].label,
    ) : [];

    storeResults(
      2,
      getFormQuestions(currentForm)[currentQuestion].label,
      isNumber(score)
        ? checkedAnswersLabels
        : getFormQuestions(currentForm)[currentQuestion].answers[selectedAnswer + 1].label,
      isNumber(score)
        ? (formIsWrong ? -15 : 0)
        : getFormQuestions(currentForm)[currentQuestion].answers[selectedAnswer + 1].score,
      formsNames[currentForm],
    );

    resetScreen();

    const nextQuestion = getFormQuestions(currentForm)[currentQuestion].nextQuestion
    || getFormQuestions(currentForm)[currentQuestion].answers[selectedAnswer + 1].nextQuestion;

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const questionWrapper = document.getElementById('questionWrapper');

    if (nextQuestion) {
      if (questionWrapper) {
        getNextQuestionWithTransition(questionWrapper, setCurrentQuestion, nextQuestion);
      }
    } else {
      setWantAnotherFormModal(true);
    }
  };

  const handleAnotherForm = (confirm) => {
    if (confirm) {
      setWantAnotherFormModal(false);
      setTimeout(() => {
        resetScreen();
        setcurrentForm(null);
      }, 300);
    } else {
      setWantAnotherFormModal(false);
      history.push('/new-chapter?c=3');
    }
  };

  const applyFormIsPerfect = () => {
    document.body.classList.add('bg-perfect');
    setFormIsPerfect(true);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const applyFormIsWrong = () => {
    document.body.classList.add('bg-wrong');
    setFormIsWrong(true);
    setScoreAfterCheck(-15);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleCheckResult = () => {
    const answers = checkedAnswers.map((answ) => getFormQuestions(currentForm)[currentQuestion].answers[answ + 1]);
    const answersTypes = Object.values(answers).map((answ) => answ.type);

    if (answersTypes.includes(3)) {
      applyFormIsWrong();
    } else if (answersTypes.includes(2)) {
      const { warningQuestion } = getFormQuestions(currentForm)[currentQuestion];
      if (warningQuestion) {
        setShowWarningModal(true);
        setWarnQuestion(warningQuestion);
      }
    } else {
      applyFormIsPerfect();
    }
  };

  const handleWarningSelect = (type) => {
    switch (+type) {
      case 1:
        setShowWarningModal(false);
        applyFormIsPerfect();
        break;
      case 3:
        setShowWarningModal(false);
        applyFormIsWrong();
        break;
      default:
        break;
    }
  };

  return getFormQuestions(currentForm) ? (
    <>
      <div className="question-wrapper" id="questionWrapper">
        {!formIsPerfect && !formIsWrong ? (
          <h2
            id={currentQuestion}
            className="question"
          >
            {getQweritips(getFormQuestions(currentForm)[currentQuestion], `${currentForm}-${currentQuestion}`)}

            <ReactTooltip
              id={`tooltip-${currentForm}-${currentQuestion}`}
              className="app-tooltip"
              clickable
              place="bottom"
              effect="solid"
            />
          </h2>
        ) : (
          <>
            {formIsPerfect ? (
              <p className="question form-perfect">
                {/* eslint-disable-next-line max-len */}
                C&apos;est probablement un sans faute ! Le principe de minimisation de la donnée semble être bien respecté dans votre formulaire !
              </p>
            ) : null}
            {formIsWrong ? (
              <p className="question">
                {/* eslint-disable-next-line max-len */}
                Attention ! Il semblerait que les données que vous collectez ne soient pas en adéquation avec le principe de minimisation de la donnée ! Certaines données collectées ne semblent pas nécessaires à la finalité de traitement de votre formulaire.
              </p>
            ) : null}
          </>
        )}

        <ul
          className={`answers
            ${Object.values(getFormQuestions(currentForm)[currentQuestion].answers).length > 3
            ? 'answers--more' : ''}
        ${getFormQuestions(currentForm)[currentQuestion].isCheckbox ? 'answers--form' : ''}
      `}
        >
          {Object.values(getFormQuestions(currentForm)[currentQuestion].answers).map((answer, answerIndex) => (
            getFormQuestions(currentForm)[currentQuestion].isCheckbox ? (
              <FormCheckbox
                key={answer.label}
                answer={answer}
                answerIndex={answerIndex}
                checkedAnswers={checkedAnswers}
                setCheckedAnswers={setCheckedAnswers}
                disabled={formIsPerfect || formIsWrong}
              />
            ) : (
              <li
                key={answer.label}
                className={`
            answers__item
            ${selectedAnswer === answerIndex ? 'selected' : ''}
          `}
              >
                <Button label={answer.label} answer onClick={() => handleSelectAnswer(answerIndex)} />
              </li>
            )
          ))}
        </ul>

        {getFormQuestions(currentForm)[currentQuestion].isCheckbox ? (
          <>
            {formIsWrong || formIsPerfect ? (
              <Button
                id="nextQuestionButton"
                label="Question suivante"
                arrow
                onClick={() => handleNextQuestion(scoreAfterCheck)}
                className="next-question"
                disabled={selectedAnswer === null && checkedAnswers.length === 0}
              />
            ) : (
              <Button
                id="nextQuestionButton"
                label="Valider"
                arrow
                onClick={handleCheckResult}
                className="next-question"
                disabled={selectedAnswer === null && checkedAnswers.length === 0}
              />
            )}
          </>
        ) : (
          <Button
            id="nextQuestionButton"
            label="Valider"
            arrow
            onClick={handleNextQuestion}
            className="next-question"
            disabled={selectedAnswer === null && checkedAnswers.length === 0}
          />
        )}
      </div>

      {currentForm && currentQuestion && !formIsPerfect && !formIsWrong ? (
        <ProgressBar currentForm={currentForm} currentQuestion={+currentQuestion} />
      ) : null}

      <Modal show={showWarningModal} setShowState={setShowWarningModal}>
        <div className="form-warning">
          {warnQuestion ? (
            <>
              <h2 className="warning-question">{warnQuestion.label}</h2>

              <ul className="answers">
                {Object.keys(warnQuestion.answers).map((key) => (
                  <li key={key} className="answers__item">
                    <Button
                      label={warnQuestion.answers[key].label}
                      answer
                      onClick={() => handleWarningSelect(warnQuestion.answers[key].type)}
                    />
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      </Modal>
      <Modal show={wantAnotherFormModal} setShowState={setWantAnotherFormModal}>
        <div className="form-warning">
          <h2 className="warning-question">Souhaitez-vous analyser un autre formulaire ?</h2>

          <ul className="answers">
            <li className="answers__item">
              <Button
                label="Oui, j'aimerais analyser un autre formulaire !"
                answer
                onClick={() => handleAnotherForm(true)}
              />
            </li>
            <li className="answers__item">
              <Button
                label="Non merci, c'est tout bon ! 😊"
                answer
                onClick={() => handleAnotherForm(false)}
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  ) : null;
};

FormQuestionnaire.propTypes = {
  currentForm: PropTypes.number.isRequired,
  setcurrentForm: PropTypes.func.isRequired,
};

export default FormQuestionnaire;
