import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import getProgression from '../../services/getProgression';

// Services
import redirectToCurrent from '../../services/redirectToCurrent';

// Components
import Questionnaire from '../Questionnaire/Questionnaire';

const Chapter3 = () => {
  const history = useHistory();

  const [currentQuestion, setCurrentQuestion] = useState(getProgression('currentQuestion'));

  useEffect(() => {
    if (redirectToCurrent(3)) {
      history.push(redirectToCurrent(3));
    }
  }, []);

  return (
    <Questionnaire currentQuestion={+currentQuestion} setCurrentQuestion={setCurrentQuestion} currentChapter={3} />
  );
};

export default Chapter3;
