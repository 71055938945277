const getNextQuestionWithTransition = (questionWrapper, setQuestion, nextQuestion) => {
  questionWrapper.classList.add('exit');

  setTimeout(() => {
    questionWrapper.classList.add('transit');
    setQuestion(nextQuestion);

    setTimeout(() => {
      questionWrapper.classList.add('enter');

      setTimeout(() => {
        questionWrapper.classList.remove('exit');
        questionWrapper.classList.remove('transit');
        questionWrapper.classList.remove('enter');
      }, 200);
    }, 200);
  }, (200));
};

export default getNextQuestionWithTransition;
