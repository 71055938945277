import React from 'react';
import PropTypes from 'prop-types';

// Utils
import getNumberOfQuestionsByChapter from '../../../utils/getNumberOfQuestionsByChapter';

const ProgressBar = ({ currentChapter, currentQuestion, currentForm }) => {
  const getProgression = () => {
    const numberOfQuestions = getNumberOfQuestionsByChapter(currentForm ? 2 : currentChapter, currentForm);
    const progression = Math.round((currentQuestion * 100) / numberOfQuestions);
    return progression;
  };

  return (
    <div className="progress-bar">
      <span className="progress-bar__inner" style={{ width: `${getProgression()}%` }}>
        <span
          className={`progress-bar__value ${getProgression() < 10 ? 'progress-bar__value--left' : ''}`}
        >
          {`${getProgression()}%`}
        </span>
      </span>
    </div>
  );
};

ProgressBar.propTypes = {
  currentChapter: PropTypes.number,
  currentQuestion: PropTypes.number.isRequired,
  currentForm: PropTypes.number,
};

ProgressBar.defaultProps = {
  currentChapter: null,
  currentForm: null,
};

export default ProgressBar;
