// Views
import Chapter1 from '../views/Chapters/Chapter1';
import Chapter2 from '../views/Chapters/Chapter2';
import Chapter3 from '../views/Chapters/Chapter3';
import Introduction from '../views/Introduction/Introduction';
import NewChapter from '../views/NewChapter/NewChapter';
import Results from '../views/Results/Results';

export default [
  {
    path: '/',
    component: Introduction,
    exact: true,
  },
  {
    path: '/chapter1',
    component: Chapter1,
    exact: true,
  },
  {
    path: '/chapter2',
    component: Chapter2,
    exact: true,
  },
  {
    path: '/chapter3',
    component: Chapter3,
    exact: true,
  },
  {
    path: '/new-chapter',
    component: NewChapter,
    exact: true,
  },
  {
    path: '/results',
    component: Results,
    exact: true,
  },
];
