/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Utils
import parse from 'html-react-parser';
import getLocalStorage from '../../utils/getLocalStorage';
import setLocalStorage from '../../utils/setLocalStorage';

// Constants
import resultsText from '../../constants/resultsText';
import startPoints from '../../constants/startPoints';
import benjamin from '../../assets/images/benjamin.jpg';

// Service
import redirectToCurrent from '../../services/redirectToCurrent';
import chapterNames from '../../constants/chapterNames';

// Components
import Button from '../../components/atoms/Button/Button';
import postWebhook from '../../services/webhook';

const Results = () => {
  const history = useHistory();

  const [contactChoice, setContactChoice] = useState(null);
  const [userData, setUserData] = useState({
    email: '',
    name: '',
    phone: '',
  });

  const [showResults, setShowResults] = useState(false);
  const [userConsent, setUserConsent] = useState(false);

  useEffect(() => {
    if (redirectToCurrent(0)) {
      history.push(redirectToCurrent(0));
    }

    if (getLocalStorage('user').name && getLocalStorage('user').email) {
      setShowResults(true);
    } else {
      const dataForm = document.getElementById('dataForm');
      if (dataForm) {
        dataForm.style.display = 'block';
        dataForm.style.opacity = '1';
      }
    }

    if (getLocalStorage('user').wantsContact) {
      setContactChoice(getLocalStorage('user').wantsContact);
    }
  }, []);

  const getScore = () => {
    const allScores = getLocalStorage('score');
    let amount = 0;

    Object.values(allScores).forEach((score) => { amount += score; });

    return amount;
  };

  const getStartPoints = () => {
    let points = getLocalStorage('formPoints');
    Object.values(startPoints).forEach((point) => { points += point; });
    return points;
  };

  const getResultText = () => {
    // 1 => 0 to 49%
    // 2 => 50% to 74%
    // 3 => 75% to 100%

    if (getScore() < (getStartPoints() * 0.5)) {
      return parse(resultsText[1]);
    } if (getScore() >= (getStartPoints() * 0.5) && getScore() < (getStartPoints() * 0.75)) {
      return parse(resultsText[2]);
    }
    return parse(resultsText[3]);
  };

  const getscorebychapter = (chapter) => {
    const score = getLocalStorage('score')[`chapter_${chapter}`];
    let percent = 0;

    if (chapter === '2') {
      percent = getLocalStorage('formPoints') ? (score * 100) / getLocalStorage('formPoints') : 0;
    } else {
      percent = (score * 100) / +startPoints[`chapter_${chapter}`];
    }

    return Math.round(percent) <= 100 ? Math.round(percent) : 100;
  };

  const getBarColor = (score) => {
    if (score <= 30) {
      return '--red';
    }
    if (score > 30 && score <= 70) {
      return '--orange';
    }
    if (score > 70) {
      return '--green';
    }
    return '';
  };

  const handleChoiceClick = (choice) => {
    setContactChoice(choice);
  };

  useEffect(() => {
    const updatedUser = getLocalStorage('user');

    if (contactChoice) {
      updatedUser.wantsContact = contactChoice;
      setLocalStorage('user', updatedUser);

      if (!getLocalStorage('user').contactSent) {
        const contactChoiceType = {
          1: 'rgpdfriendly',
          2: 'opentotalk',
        };

        const contact = {
          type: contactChoice === 3 ? 'optout' : 'contact',
          Name: getLocalStorage('user').name,
          Email: getLocalStorage('user').email,
          Website: getLocalStorage('user').website,
          Phone: getLocalStorage('user').phone,
          Score: `${getScore()} sur ${getStartPoints()}`,
        };

        if (contactChoice === 1 || contactChoice === 2) {
          contact.button = contactChoiceType[contactChoice];
        }

        // [WEBHOOK] send contact
        postWebhook(contact).then(() => {
          updatedUser.contactSent = true;
          setLocalStorage('user', updatedUser);
        });
      }
    }
  }, [contactChoice]);

  const handleInputChange = (input) => {
    setUserData((prevState) => ({
      ...prevState,
      [input.target.name]: input.target && input.target.value && input.target.value.trim() ? input.target.value : '',
    }));
  };

  const pushToWebHook = () => {
    const contact = {
      type: 'contact',
      Name: getLocalStorage('user').name,
      Email: getLocalStorage('user').email,
      Website: getLocalStorage('user').website,
      Phone: getLocalStorage('user').phone,
      Score: `${getScore()} sur ${getStartPoints()}`,
    };

    // [WEBHOOK] send contact
    postWebhook(contact);

    // All results
    if (getLocalStorage('results') && getLocalStorage('results').length > 0) {
      const resultsToPush = getLocalStorage('results');
      const answers = [];

      resultsToPush.forEach((result) => {
        answers.push({
          type: 'answers',
          User: `${userData.email} (${userData.name})`,
          Chapter: result.chapter,
          Form: result.form,
          Question: result.question,
          Answer: result.answer,
          Points: result.points,
        });
      });

      // [WEBHOOK] send answers
      postWebhook(answers);
    }
  };

  const handleSubmitData = () => {
    const userStorage = getLocalStorage('user');
    userStorage.email = userData.email;
    userStorage.name = userData.name;
    userStorage.phone = userData.phone || null;
    setLocalStorage('user', userStorage);

    const dataForm = document.getElementById('dataForm');

    if (dataForm) {
      dataForm.style.opacity = 0;

      setTimeout(() => {
        dataForm.style.display = 'none';
        setShowResults(true);
      }, 400);
    }

    pushToWebHook();

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (showResults) {
      const resultsContent = document.getElementById('resultsContent');

      if (resultsContent) {
        setTimeout(() => {
          resultsContent.style.opacity = 1;
        }, 100);
      }
    }
  }, [showResults]);

  return (
    <section className="results max-wrapper">
      {showResults ? (
        <div id="resultsContent" className={`results__content ${showResults ? '' : 'hide-results'}`}>
          <h1>Votre score RGPD</h1>

          <div className="results__score">
            <span>{getScore()}</span>
            <span>{`sur ${getStartPoints()} points`}</span>
          </div>

          <p className="results__text">{getResultText()}</p>

          <div className="results__details">
            <h2 className="results__details__title">Vos résultats par sections</h2>

            <ul className="statistics">
              {Object.keys(chapterNames).map((key) => (
                <li key={key} className="statistics__item">
                  <p className="statistics__label">{`${key} : ${chapterNames[key]}`}</p>
                  <div className="statistics__value">
                    <span className="statistics__percent">
                      {`${getscorebychapter(key)}%`}
                    </span>
                    <span className="statistics__bar">
                      <span
                        className={`
                          statistics__bar__value 
                          statistics__bar__value${getBarColor(getscorebychapter(key))}
                        `}
                        style={{ width: `${getscorebychapter(key)}%` }}
                      />
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <h2 className="contact-us__title">Vous avez besoin d’un petit coup de pouce pour être 100% compliant ?</h2>

          <div className="contact-us__block">
            <div className="contact-us__avatar">
              <img src={benjamin} alt="Benjamin" />
            </div>

            <ul className="contact-us__choices">
              {!contactChoice ? (
                <>
                  <li className="contact-us__choices__item">
                    <button type="button" onClick={() => handleChoiceClick(1)}>
                      100% motivés à rendre mon site RGPD Friendly avec vous !
                    </button>
                  </li>
                  <li className="contact-us__choices__item">
                    <button type="button" onClick={() => handleChoiceClick(2)}>
                      Je suis ouvert à un échange
                    </button>
                  </li>
                  <li className="contact-us__choices__item">
                    <button type="button" onClick={() => handleChoiceClick(3)}>
                      J&apos;ai encore quelques chantiers à finaliser avant
                    </button>
                  </li>
                </>
              ) : (
                <li className="contact-us__after-choice">
                  {contactChoice === 3 ? (
                    <p>À bientôt pour vos futurs chantiers RGPD</p>
                  ) : (
                    <p>On revient vers vous très vite ! 🚀</p>
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>
      ) : null}
      <form id="dataForm" className={`data-form ${showResults ? 'hide-form' : ''}`}>
        <h1>Hâte de découvrir votre score ? </h1>

        <div className="data-form__block">
          <label htmlFor="email">
            <span>Votre adresse e-mail *</span>
            <input
              name="email"
              id="email"
              type="text"
              placeholder="E-mail"
              value={userData.email}
              onChange={handleInputChange}
            />
          </label>
          <label htmlFor="email">
            <span>Le nom de votre société *</span>
            <input
              name="name"
              id="name"
              type="text"
              placeholder="Nom"
              value={userData.name}
              onChange={handleInputChange}
            />
          </label>
          <label htmlFor="email">
            <span>Votre numéro de téléphone (optionnel)</span>
            <input
              name="phone"
              id="phone"
              type="text"
              placeholder="Téléphone"
              value={userData.phone}
              onChange={handleInputChange}
            />
          </label>
        </div>

        <div className="data-form__consent">
          <label htmlFor="dataConsent" className="form-checkbox">
            <input
              id="dataConsent"
              type="checkbox"
              checked={userConsent}
              onChange={(input) => setUserConsent(input.target.checked)}
            />
            <span className="form-checkbox__check">
              <svg
                width="24"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
            </span>
            <span className="form-checkbox__label">
              J’accepte de donner le nom de ma société et mon e-mail, afin de permettre à l&apos;équipe Qweri de savoir qui a utilisé les services d&apos;Inspecteur RGPD : vos données seront supprimées sous 6 mois *
            </span>
          </label>
        </div>

        <div className="data-form__submit">
          <Button
            disabled={!userData.email || !userData.name || !userConsent}
            label="Voir mes résultats"
            arrow
            onClick={handleSubmitData}
          />
        </div>
      </form>
    </section>
  );
};

export default Results;
