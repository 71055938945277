/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
// import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CookiesManager = ({
  hideBanner,
  GDPRAnalytics,
  setGDPRAnalytics,
  GDPRPersonnalisation,
  setGDPRPersonnalisation,
  GDPRConversion,
  setGDPRConversion,
  GDPRRemarketing,
  setGDPRRemarketing,
}) => {
  const closeModal = () => {
    const cookiesManagerModal = document.querySelector('#cookiesManagerModal');
    if (cookiesManagerModal) {
      cookiesManagerModal.style.opacity = 0;
      cookiesManagerModal.style.visibility = 'hidden';
    }
  };

  const handleSaveClick = () => {
    const gdprChoices = localStorage.getItem('userGDPR');

    if (gdprChoices) {
      window.dataLayer.push({
        event: 'eventGA',
        categoryGA: 'Cookies',
        actionGA: 'Enregistrer',
        labelGA: 'OK',
        valueGA: '0',
        nonInteraction: 'false',
        userChoicesGDPR: JSON.parse(gdprChoices),
      });
    }

    closeModal();
    hideBanner();
  };

  return (
    <section id="cookiesManagerModal" className="cookies-consent-params">
      <div className="cookies-consent-params__popup">
        <div className="cookies-title">
          Paramétrez vos cookies
        </div>

        <ul className="cookies-list">
          <li className="cookies-list__item">
            <div className="cookies__header">
              <p className="cookies-list__item__label">
                Les cookies d’analyse
              </p>
              <label className="cookies__switch">
                <input checked={GDPRAnalytics} type="checkbox" onChange={(input) => setGDPRAnalytics(input.target.checked)} />
                <span className="cookies__switch__slider" />
              </label>
            </div>

            <p className="cookies-desc">
              Les cookies Google Analytics nous aident à comprendre comment nos utilisateurs interagissent avec notre site web et avec nos contenus. Ils nous permettent d’établir des statistiques anonymes sur les comportements utilisateurs. Ce sont eux qui nous aident améliorer votre expérience utilisateur.
            </p>
          </li>
          <li className="cookies-list__item">
            <div className="cookies__header">
              <p className="cookies-list__item__label">
                Les cookies de personnalisation
              </p>
              <label className="cookies__switch">
                <input checked={GDPRPersonnalisation} type="checkbox" onChange={(input) => setGDPRPersonnalisation(input.target.checked)} />
                <span className="cookies__switch__slider" />
              </label>
            </div>

            <p className="cookies-desc">
              Les cookies de personnalisation nous permettent comme leur nom l’indique de personnaliser votre expérience avec notre site web (exemples : détecter quand vous êtes perdu(e) pour vous proposer de l’aide, identifier les sujets qui vous intéressent pour vous rediriger vers les offres les plus pertinentes...)
            </p>
          </li>
          <li className="cookies-list__item">
            <div className="cookies__header">
              <p className="cookies-list__item__label">
                Les cookies publicitaires
              </p>
              <label className="cookies__switch">
                <input checked={GDPRRemarketing} type="checkbox" onChange={(input) => setGDPRRemarketing(input.target.checked)} />
                <span className="cookies__switch__slider" />
              </label>
            </div>

            <p className="cookies-desc">
              Les cookies publicitaires nous permettent de vous proposer une expérience immersive, répondant à vos intérêts et à vos besoins. Nous pouvons par exemple vous adresser des publicités qui vous aideraient à aboutir à votre projet Data Marketing, suite à la consultation de plusieurs de nos pages relatives à un sujet qui vous intéresse.
            </p>
          </li>
          <li className="cookies-list__item">
            <div className="cookies__header">
              <p className="cookies-list__item__label">
                Les cookies de conversion
              </p>
              <label className="cookies__switch">
                <input checked={GDPRConversion} type="checkbox" onChange={(input) => setGDPRConversion(input.target.checked)} />
                <span className="cookies__switch__slider" />
              </label>
            </div>

            <p className="cookies-desc">
              Lorsque vous interagissez avec nos publicités, nous dépensons de l’argent. Les cookies de conversion ont donc pour objectif de nous aider à attribuer ces conversions : montant dépensé, emplacement de la publicité
            </p>
          </li>
        </ul>

        <div className="cookies-validate">
          <button onClick={handleSaveClick} type="button" className="cookies-validate__btn">Enregistrer mes choix</button>
        </div>
      </div>
    </section>
  );
};

CookiesManager.propTypes = {
  hideBanner: PropTypes.func,
  GDPRAnalytics: PropTypes.bool.isRequired,
  setGDPRAnalytics: PropTypes.func.isRequired,
  GDPRConversion: PropTypes.bool.isRequired,
  setGDPRConversion: PropTypes.func.isRequired,
  GDPRPersonnalisation: PropTypes.bool.isRequired,
  setGDPRPersonnalisation: PropTypes.func.isRequired,
  GDPRRemarketing: PropTypes.bool.isRequired,
  setGDPRRemarketing: PropTypes.func.isRequired,
};

CookiesManager.defaultProps = {
  hideBanner: () => {},
};

export default CookiesManager;
