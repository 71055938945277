import getLocalStorage from '../utils/getLocalStorage';

const setCurrentForm = (currentForm) => {
  const storage = getLocalStorage();
  if (storage) {
    storage.currentForm = +currentForm;
    localStorage.setItem(`qweri-module-rgpd-v${process?.env?.REACT_APP_VERSION}`, JSON.stringify(storage));
  }
};

export default setCurrentForm;
