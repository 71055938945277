import parse from 'html-react-parser';

const getQweritips = (question, questionId) => {
  if (question && questionId !== undefined && question.qweritips) {
    if (question.label.includes('<span>')) {
      const questionWithQweritips = question.label.replace(
        '<span>',
        `<span data-for="tooltip-${questionId}" data-tip="${question.qweritips}">`,
      );
      return parse(questionWithQweritips);
    }
  }
  return question ? parse(question.label) : '';
};

export default getQweritips;
