import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  id, label, arrow, answer, onClick, className, disabled,
}) => (
  <button
    id={id}
    type="button"
    onClick={onClick}
    className={`button ${answer ? 'button--answer' : ''} ${className}`}
    disabled={disabled}
  >
    {label}

    {arrow ? (
      <svg
        width="28"
        height="28"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="5" y1="12" x2="19" y2="12" />
        <line x1="13" y1="18" x2="19" y2="12" />
        <line x1="13" y1="6" x2="19" y2="12" />
      </svg>
    ) : null}
  </button>
);

Button.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.any)]).isRequired,
  arrow: PropTypes.bool,
  answer: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  id: null,
  arrow: false,
  answer: false,
  onClick: () => undefined,
  className: '',
  disabled: false,
};

export default Button;
