import React from 'react';
import PropTypes from 'prop-types';

const FormCheckbox = ({
  answer, answerIndex, checkedAnswers, setCheckedAnswers, disabled,
}) => {
  const handleChangeCheckbox = (input, selectedAnswer) => {
    if (input.target) {
      if (input.target.checked && !checkedAnswers.includes(selectedAnswer)) {
        // ajouter au tab
        setCheckedAnswers((prevState) => [...prevState, selectedAnswer]);
      } else if (!input.target.checked && checkedAnswers.includes(selectedAnswer)) {
        const updatedCheckedAnswers = [...checkedAnswers];
        const checkedItemIndex = updatedCheckedAnswers.indexOf(selectedAnswer);
        updatedCheckedAnswers.splice(checkedItemIndex, 1);
        setCheckedAnswers(updatedCheckedAnswers);
        // retirer du tab
      }
    }
  };

  return (
    <li className={`
      answers__item
      ${checkedAnswers.includes(answerIndex) ? 'checked' : ''}
      ${disabled ? 'disabled' : ''}
    `}
    >
      <label htmlFor={`checkbox-${answerIndex}`} className="form-checkbox">
        <input
          disabled={disabled}
          id={`checkbox-${answerIndex}`}
          type="checkbox"
          onChange={(input) => handleChangeCheckbox(input, answerIndex)}
        />
        <span
          className={`form-checkbox__check 
          ${disabled && answer.type === 3 ? 'form-checkbox__check--wrong' : ''} 
          ${disabled && answer.type === 2 ? 'form-checkbox__check--warning' : ''}`}
        >
          {!disabled ? (
            <svg
              width="24"
              viewBox="0 0 24 24"
              strokeWidth="2.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 12l5 5l10 -10" />
            </svg>
          ) : null}
          {disabled && answer.type === 1 ? (
            <svg
              width="24"
              viewBox="0 0 24 24"
              strokeWidth="2.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 12l5 5l10 -10" />
            </svg>
          ) : null}
          {disabled && answer.type === 3 ? (
            <svg width="15" height="15" viewBox="0 0 329.26933 329" xmlns="http://www.w3.org/2000/svg">
              {/* eslint-disable-next-line max-len */}
              <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
            </svg>
          ) : null}
        </span>
        <span className="form-checkbox__label">{answer.label}</span>
      </label>
    </li>
  );
};

FormCheckbox.propTypes = {
  answer: PropTypes.objectOf(PropTypes.any).isRequired,
  answerIndex: PropTypes.number.isRequired,
  checkedAnswers: PropTypes.arrayOf(PropTypes.number).isRequired,
  setCheckedAnswers: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default FormCheckbox;
