import getLocalStorage from '../utils/getLocalStorage';

const getProgression = (key) => {
  const storage = getLocalStorage();
  if (key) return storage ? storage[key] : null;
  return storage ? {
    chapter: storage.currentChapter,
    question: storage.currentQuestion,
    form: storage.currentForm,
  } : null;
};

export default getProgression;
