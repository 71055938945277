import startPoints from '../constants/startPoints';

const initLocalStorage = (userWebsite) => {
  if (!localStorage.getItem(`qweri-module-rgpd-v${process?.env?.REACT_APP_VERSION}`)) {
    localStorage.setItem(`qweri-module-rgpd-v${process?.env?.REACT_APP_VERSION}`, JSON.stringify({
      score: startPoints,
      formPoints: 0,
      currentForm: null,
      currentChapter: 1,
      currentQuestion: 1,
      results: [],
      newChapter: null,
      user: {
        website: userWebsite,
        name: null,
        phone: null,
        wantsContact: null,
        contactSent: null,
      },
      formsAlreadyCompleted: [],
      cookiesAlreadyCompleted: [],
      businessType: null,
    }));
  }
};

export default initLocalStorage;
