/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Button from '../../components/atoms/Button/Button';

// Constants
import chapterNames from '../../constants/chapterNames';

// Utils
import getLocalStorage from '../../utils/getLocalStorage';
import setLocalStorage from '../../utils/setLocalStorage';
import getNextQuestionWithTransition from '../../utils/getNextQuestionWithTransition';

// Services
import redirectToCurrent from '../../services/redirectToCurrent';
import setCurrentForm from '../../services/setCurrentForm';
import getProgression from '../../services/getProgression';
import editScore from '../../services/editScore';

// Components
import FormQuestionnaire from '../FormQuestionnaire/FormQuestionnaire';
import { formsB2B, formsB2C, formsNames } from '../../constants/formsNames';

const Chapter2 = () => {
  const history = useHistory();

  const [selectedForm, setSelectedForm] = useState(null);
  const [currentForm, setcurrentForm] = useState(getLocalStorage('currentForm'));

  const [businessType, setBusinessType] = useState(null);

  const handleSelectAnswer = (formId) => {
    setSelectedForm(formId);
    editScore(2, 15);
    setLocalStorage('formPoints', getLocalStorage('formPoints') + 15);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (redirectToCurrent(2)) {
      history.push(redirectToCurrent(2));
    }

    setBusinessType(getLocalStorage('businessType') || null);
  }, []);

  useEffect(() => {
    const questionWrapper = document.getElementById('questionWrapper');

    if (selectedForm && questionWrapper) {
      const updatedCompletedForms = getLocalStorage('formsAlreadyCompleted');
      updatedCompletedForms.push(selectedForm);
      setLocalStorage('formsAlreadyCompleted', updatedCompletedForms);
      getNextQuestionWithTransition(questionWrapper, setcurrentForm, selectedForm);
      setSelectedForm(null);
    }
  }, [selectedForm]);

  useEffect(() => {
    setCurrentForm(currentForm);
  }, [currentForm]);

  const handleChooseBusinessType = (type) => {
    setBusinessType(type);
    setLocalStorage('businessType', type);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getFormsByBuinessType = () => {
    const type = businessType === 'b2b' ? formsB2B : (businessType === 'b2c' ? formsB2C : null);
    if (type === null) return {};

    const filteredList = Object.keys(formsNames)
      .filter((key) => type.includes(+key))
      .reduce((obj, key) => ({
        ...obj,
        [key]: formsNames[key],
      }), {});

    return filteredList || {};
  };

  return (
    <section className="questionnaire max-wrapper">
      <h1 className="chapter-name">
        {`2 : ${chapterNames[2]}`}
      </h1>
      {currentForm ? (
        <h3 className="form-name">
          {`${formsNames[currentForm]}`}
        </h3>
      ) : null}

      {currentForm && getProgression('currentChapter') !== 0 ? (
        <FormQuestionnaire currentForm={+currentForm} setcurrentForm={setcurrentForm} />
      ) : (
        <>
          {businessType ? (
            <div className="question-wrapper" id="questionWrapper">
              <h2 className="question">
                Quel type de formulaire souhaitez-vous analyser ?
              </h2>

              <ul className="answers answers--form-choice">
                {formsNames && Object.values(formsNames).length > 0 ? (
                  Object.keys(getFormsByBuinessType()).map((key) => (
                    <li
                      id={`form-${key}`}
                      key={key}
                      className="answers__item"
                    >
                      <Button
                        disabled={getLocalStorage('formsAlreadyCompleted').includes(key)}
                        label={getFormsByBuinessType()[key]}
                        answer
                        onClick={() => handleSelectAnswer(key)}
                      />
                    </li>
                  ))
                ) : null }
              </ul>

              {getLocalStorage('formsAlreadyCompleted').length > 0 ? (
                <button type="button" onClick={() => history.push('/new-chapter?c=3')} className="skip-forms">Je ne souhaite pas analyser d&apos;autre formulaire</button>
              ) : null}
            </div>
          ) : (
            <>
              <div className="question-wrapper" id="questionWrapper">
                <h2 className="question">
                  Il est maintenant temps de faire un petit détour par les formulaires, cet outil indispensable qui nous permet d&apos;interagir et de mieux connaître nos utilisateurs tout en respectant leurs
                  <span
                    data-for="tooltip-business-type"
                    data-tip="Qwertips : N'oubliez pas, une adresse email professionnelle est une donnée à caractère personnel, étant donnée qu'elle permet d'identifier individuellement une personne à l'inverse d'une adresse généraliste (contact@votreentreprise.fr)."
                  >
                  &nbsp;données à caractère personnel.
                  </span>
                  <br />
                  Avant de commencer, une petite précision : avez-vous plutôt une activité B2B ou B2C ?

                  <ReactTooltip id="tooltip-business-type" className="app-tooltip" clickable place="bottom" effect="solid" />
                </h2>

                <ul className="answers">
                  <li className="answers__item">
                    <Button
                      label="Mes activités sont plutôt en B2B !"
                      onClick={() => handleChooseBusinessType('b2b')}
                      answer
                    />
                  </li>
                  <li className="answers__item">
                    <Button
                      label="Mes activités sont plutôt en B2C !"
                      onClick={() => handleChooseBusinessType('b2c')}
                      answer
                    />
                  </li>
                </ul>
              </div>
            </>
          )}
        </>
      )}
    </section>
  );
};

export default Chapter2;
