/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import CookiesManager from './CookiesManager';

let cookiesBanner;

const Cookies = () => {
  const [fistInit, setFirstInit] = useState(true);

  const [GDPRAnalytics, setGDPRAnalytics] = useState(false);
  const [GDPRPersonnalisation, setGDPRPersonnalisation] = useState(false);
  const [GDPRRemarketing, setGDPRRemarketing] = useState(false);
  const [GDPRConversion, setGDPRConversion] = useState(false);

  const handleSwitchChange = (checked, key, value) => {
    if (!fistInit) {
      for (let i = 0; i < window.dataLayer.length; i++) {
        if (window.dataLayer[i].UserGDPR !== undefined) {
          window.dataLayer[i].UserGDPR[key] = checked ? `${value}_opt_in` : `${value}_opt_out`;
          localStorage.setItem('userGDPR', JSON.stringify(window.dataLayer[i].UserGDPR));
        }
      }
    }
  };

  useEffect(() => {
    handleSwitchChange(GDPRAnalytics, 'GDPRAnalytics', 'analytics');
  }, [GDPRAnalytics]);
  useEffect(() => {
    handleSwitchChange(GDPRPersonnalisation, 'GDPRPersonnalisation', 'perso');
  }, [GDPRPersonnalisation]);
  useEffect(() => {
    handleSwitchChange(GDPRRemarketing, 'GDPRRemarketing', 'rem');
  }, [GDPRRemarketing]);
  useEffect(() => {
    handleSwitchChange(GDPRConversion, 'GDPRConversion', 'conv');
  }, [GDPRConversion]);

  const initDatalayer = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      UserGDPR: {
        GDPRRemarketing: 'rem_opt_out',
        GDPRPersonnalisation: 'perso_opt_out',
        GDPRConversion: 'conv_opt_out',
        GDPRAnalytics: 'analytics_opt_out',
      },
    });
  };

  const updateSwitchFromStorage = () => {
    const storage = JSON.parse(localStorage.getItem('userGDPR'));
    setGDPRAnalytics(storage.GDPRAnalytics.includes('opt_in'));
    setGDPRConversion(storage.GDPRConversion.includes('opt_in'));
    setGDPRPersonnalisation(storage.GDPRPersonnalisation.includes('opt_in'));
    setGDPRRemarketing(storage.GDPRRemarketing.includes('opt_in'));
  };

  const populateDatalayerFromStorage = () => {
    if (localStorage.getItem('userGDPR') !== null) {
      for (let i = 0; i < window.dataLayer.length; i++) {
        if (window.dataLayer[i].UserGDPR !== undefined) {
          window.dataLayer[i].UserGDPR = JSON.parse(localStorage.getItem('userGDPR'));
        }
      }

      updateSwitchFromStorage();
    } else {
      cookiesBanner.classList.remove('hidden');
    }
  };

  const pushUserId = () => {
    if (localStorage.getItem('userGDPR-id') !== null) {
      window.dataLayer.push({
        'UserGDPR-id': localStorage.getItem('userGDPR-id'),
      });
    } else {
      const now = new Date();

      // Unique user id
      const userId = String.fromCharCode(65 + Math.floor(Math.random() * 26))
          + String.fromCharCode(65 + Math.floor(Math.random() * 26))
          + now.getFullYear()
          + ((now.getMonth() < 10) ? '0' : '')
          + ((now.getMonth() + 1))
          + ((now.getDate() < 10) ? '0' : '')
          + now.getDate()
          + now.getHours()
          + now.getMinutes()
          + now.getSeconds()
          + now.getMilliseconds();

      localStorage.setItem('userGDPR-id', userId);
      window.dataLayer.push({
        'UserGDPR-id': localStorage.getItem('userGDPR-id'),
      });
    }
  };

  useEffect(() => {
    if (fistInit) {
      setFirstInit(false);
      cookiesBanner = document.getElementById('cookiesBanner');

      initDatalayer();
      populateDatalayerFromStorage();
      pushUserId();
    }
  }, []);

  const handleManageOpening = () => {
    const cookiesManagerModal = document.getElementById('cookiesManagerModal');
    if (cookiesManagerModal) {
      cookiesManagerModal.style.visibility = 'visible';
      cookiesManagerModal.style.opacity = 1;
    }

    if (localStorage.getItem('userGDPR') === null) {
      for (let i = 0; i < window.dataLayer.length; i++) {
        if (window.dataLayer[i].UserGDPR !== undefined) {
          localStorage.setItem('userGDPR', JSON.stringify(window.dataLayer[i].UserGDPR));
        }
      }
    }
  };

  const hideBanner = () => {
    cookiesBanner.classList.add('hidden');
  };

  const afterRefuseOrAcceptAll = (choice) => {
    for (let i = 0; i < window.dataLayer.length; i++) {
      if (window.dataLayer[i].UserGDPR !== undefined) {
        window.dataLayer[i].UserGDPR = choice;
      }
    }

    localStorage.setItem('userGDPR', JSON.stringify(choice));
    updateSwitchFromStorage();
    hideBanner();
  };

  const handleAcceptAll = () => {
    const acceptAll = {
      GDPRRemarketing: 'rem_opt_in',
      GDPRPersonnalisation: 'perso_opt_in',
      GDPRConversion: 'conv_opt_in',
      GDPRAnalytics: 'analytics_opt_in',
    };
    afterRefuseOrAcceptAll(acceptAll);
  };

  const handleRefuseAll = () => {
    const refuseAll = {
      GDPRRemarketing: 'rem_opt_out',
      GDPRPersonnalisation: 'perso_opt_out',
      GDPRConversion: 'conv_opt_out',
      GDPRAnalytics: 'analytics_opt_out',
    };
    afterRefuseOrAcceptAll(refuseAll);
  };

  const handleOpenModal = () => {
    const cookiesManagerModal = document.getElementById('cookiesManagerModal');
    if (cookiesManagerModal) {
      cookiesManagerModal.style.visibility = 'visible';
      cookiesManagerModal.style.opacity = 1;
    }
  };

  return (
    <div>
      <section id="cookiesBanner" className="cookies-consent hidden">
        <p className="cookies-consent__content">
          Chère Utilisatrice, Cher Utilisateur, en tant que collecteur de données, Qweri utilise des cookies pour étudier de manière totalement anonymisée les interactions que vous avez avec notre site web pour nous permettre d’améliorer nos contenus, nos offres, et les fonctionnalités que nous vous proposons. Et afin de vous faire profiter d’une expérience totale avec nos agitateurs de données, nous mettons également en place des fonctionnalités de personnalisation d’expérience utilisateur, ainsi que des actions marketing qui vous permettront d’entendre encore parler de nous lorsque vous en aurez besoin...
          {' '}
          <a href="https://www.qweri.fr/politique-de-confidentialite/" target="_blank" rel="noreferrer">Un peu de lecture ?</a>
        </p>

        <div className="cookies-consent__actions">
          <button
            type="button"
            onClick={handleAcceptAll}
            className="cookies-consent__actions__item cookies-consent__button"
          >
            <span className="action-highlight">
              J&apos;accepte
            </span>
            <span>
              le tracking afin de profiter d’une expérience Qweri optimale 😊
            </span>
          </button>
          <button
            type="button"
            onClick={handleRefuseAll}
            className="cookies-consent__actions__item cookies-consent__button"
          >
            <span className="action-highlight">
              Je refuse
            </span>
            <span>
              de bénéficier d&apos;une expérience totalement personnalisée, je n&apos;accepte aucun tracking
            </span>
          </button>
          <button
            type="button"
            onClick={handleManageOpening}
            className="cookies-consent__actions__item cookies-consent__link"
          >
            Je souhaite paramétrer mon expérience
          </button>
        </div>
      </section>

      <CookiesManager
        hideBanner={hideBanner}
        GDPRAnalytics={GDPRAnalytics}
        setGDPRAnalytics={setGDPRAnalytics}
        GDPRConversion={GDPRConversion}
        setGDPRConversion={setGDPRConversion}
        GDPRPersonnalisation={GDPRPersonnalisation}
        setGDPRPersonnalisation={setGDPRPersonnalisation}
        GDPRRemarketing={GDPRRemarketing}
        setGDPRRemarketing={setGDPRRemarketing}
      />

      <div className="cookies-config-opener">
        <button type="button" onClick={handleOpenModal}>Paramétrer mes cookies</button>
      </div>
    </div>
  );
};

export default Cookies;
