const getLocalStorage = (key) => {
  const storage = localStorage.getItem(`qweri-module-rgpd-v${process?.env?.REACT_APP_VERSION}`);
  if (storage) {
    if (key) {
      const storageParsed = JSON.parse(storage);
      return storageParsed[key];
    }
    return JSON.parse(storage);
  }
  return null;
};

export default getLocalStorage;
