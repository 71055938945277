import chapter1 from '../constants/chapter1.json';
import chapter2 from '../constants/chapter2.json';
import chapter3 from '../constants/chapter3.json';

const getQuestions = (chapterId) => {
  switch (chapterId) {
    case 1:
      return chapter1.questions;
    case 2:
      return chapter2.questions;
    case 3:
      return chapter3.questions;
    default:
      return null;
  }
};

export default getQuestions;
