import getLocalStorage from '../utils/getLocalStorage';
import getProgression from './getProgression';

const redirectToCurrent = (currentLocation) => {
  const currentChapter = getProgression('currentChapter');
  if (getLocalStorage('newChapter')) return `/new-chapter?c=${getLocalStorage('newChapter')}`;
  if (currentChapter === 0 && currentLocation !== 0) return '/results';
  if (currentChapter && currentLocation !== currentChapter) {
    return `/chapter${currentChapter}`;
  }

  return null;
};

export default redirectToCurrent;
