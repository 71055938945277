/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

// Utils
import { useHistory } from 'react-router-dom';

// Components
import Button from '../../components/atoms/Button/Button';

// Constants
import chapterNames from '../../constants/chapterNames';

// Services
import initLocalStorage from '../../services/initLocalStorage';
import redirectToCurrent from '../../services/redirectToCurrent';

const Introduction = () => {
  const history = useHistory();
  const [userWebsite, setUserWebsite] = useState('');

  const [urlFormatError, setUrlFormatError] = useState(false);

  useEffect(() => {
    if (redirectToCurrent(-1)) {
      history.push(redirectToCurrent(-1));
    }
  }, []);

  const handleLaunch = () => {
    const correctUrl = userWebsite.includes('www.')
      ? new RegExp(/^((http|https):\/\/)(www\.)([a-zA-Z0-9_.-]+)\.([A-z]{2,})/).test(userWebsite)
      : new RegExp(/^((http|https):\/\/)([a-zA-Z0-9_.-]+)\.([A-z]{2,})/).test(userWebsite);

    if (correctUrl) {
      initLocalStorage(userWebsite);
      history.push('/new-chapter?c=1');
    } else {
      setUrlFormatError(true);
    }
  };

  return (
    <div className="green-triangle">
      <section className="introduction max-wrapper">
        <h1>Inspecteur RGPD : Testez le niveau de conformité de votre site web !</h1>

        <p>
          Agitateurs de données passionnés, nous sommes allés regarder d&apos;un peu plus près quelles étaient vos <strong>interrogations sur le RGPD</strong> : <em>sanction RGPD, whatsapp RGPD, article 9 RGPD, RGPD signification, dpo rgpd, audit rgpd, rgpd def, norme rgpd et beaucoup d&apos;autres...</em>
          <br />
          <br />
          On s&apos;est donc dit qu&apos;il était peut-être temps de rassembler toutes les réponses à vos interrogations via un <strong>petit test pédagogique !</strong>
          <br />
          Loin de nous l&apos;idée de remettre à plat toute votre organisation, nous avons avant tout cherché à centraliser au sein d&apos;un même outil toutes les questions qui se posent principalement autour de votre site web. Pourquoi ? Parce que c&apos;est la première vitrine qui vous lie à vos clients, vos prospects, mais aussi à vos concurrents !
          <br />
          <span className="secondary">On a donc lancé l&apos;Inspecteur RGPD, le premier <strong>outil de test RGPD</strong> dédié aux sites Internet.</span>
          <br />
          <br /> <strong>5 minutes pour tester votre site web !</strong>
        </p>

        <p><em>
          Qweri, toute son équipe, et Inspecteur RGPD, ne peuvent être tenus pour responsables, ni de l&apos;absence de mise en conformité de votre site web, ni des réponses que vous apportez.</em>
        </p>

        <ul className="num-list">
          {Object.keys(chapterNames).map((key) => (
            <li key={key}>
              {`${key} : ${chapterNames[key]}`}
            </li>
          ))}
        </ul>

        <div id="mobileFixed" className="mobile-fixed">
          <div className="website-input">
            <p>Avant de commencer, pouvez-vous nous indiquer l&apos;adresse de votre site Internet ? </p>
            <input type="text" placeholder="https://www.qweri.fr" value={userWebsite} onChange={(input) => setUserWebsite(input.target.value.trim())} className={urlFormatError ? 'error' : ''} />

            {urlFormatError ? (
              <span className="website-input__error">
                Oups, il semblerait que l&apos;adresse de votre site Internet ne soit pas une URL valide. Pour que votre adresse soit valide, elle doit commencer par &quot;http://&quot; ou &quot;https://&quot; et se terminer par une extension valide.
              </span>
            ) : null}
          </div>

          <Button className="btn-go" label="C'est parti !" arrow disabled={!userWebsite} onClick={handleLaunch} />
        </div>
      </section>
    </div>
  );
};

Introduction.propTypes = {};

export default Introduction;
