import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import getQuestions from '../../utils/getQuestions';
import getQweritips from '../../utils/getQweritips';
import getNextQuestionWithTransition from '../../utils/getNextQuestionWithTransition';
import setLocalStorage from '../../utils/setLocalStorage';
import getLocalStorage from '../../utils/getLocalStorage';

// Services
import editScore from '../../services/editScore';
import setProgression from '../../services/setProgression';
import storeResults from '../../services/storeResults';

// Constants
import chapterNames from '../../constants/chapterNames';

// Components
import Button from '../../components/atoms/Button/Button';
import ProgressBar from '../../components/molecules/ProgressBar/ProgressBar';

const Questionnaire = ({ currentChapter, currentQuestion, setCurrentQuestion }) => {
  const history = useHistory();

  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const [answerAlert, setAnswerAlert] = useState(null);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [currentQuestion]);

  useEffect(() => {
    if (currentQuestion > 1) {
      setProgression(currentChapter, currentQuestion);
    }
  }, [currentQuestion]);

  const handleSelectAnswer = (answer) => {
    setSelectedAnswer(answer);

    const nextButton = document.getElementById('nextQuestionButton');
    if (nextButton) {
      window.scrollTo({
        top: nextButton.getBoundingClientRect().top + nextButton.offsetHeight + 100,
        behavior: 'smooth',
      });
    }
  };

  const goToNextQuestion = (answer) => {
    const questionWrapper = document.getElementById('questionWrapper');

    if (answer.isCookie) {
      const updatedCompletedCookies = getLocalStorage('cookiesAlreadyCompleted');
      updatedCompletedCookies.push(selectedAnswer);
      setLocalStorage('cookiesAlreadyCompleted', updatedCompletedCookies);
    }

    if (answer.nextQuestion) {
      if (questionWrapper) {
        getNextQuestionWithTransition(questionWrapper, setCurrentQuestion, answer.nextQuestion);
      }
    } else if (currentChapter < Object.entries(chapterNames).length) {
      history.push(`/new-chapter?c=${currentChapter + 1}`);
    } else {
      setProgression(0, 0);
      history.push('/results');
    }
  };

  const handleNextQuestion = (skipAlert) => {
    const answer = getQuestions(currentChapter)[currentQuestion].answers[selectedAnswer + 1];

    if (skipAlert) {
      document.body.classList.remove('bg-wrong');
      setAnswerAlert(null);
      goToNextQuestion(answer);
    } else {
      editScore(currentChapter, answer.score);
      setSelectedAnswer(null);
      window.scrollTo({ top: 0, behavior: 'smooth' });

      storeResults(
        currentChapter,
        getQuestions(currentChapter)[currentQuestion].label,
        answer.label,
        answer.score,
      );

      if (answer.alert) {
        setAnswerAlert(answer.alert);
        document.body.classList.add('bg-wrong');
      } else {
        goToNextQuestion(answer);
      }
    }
  };

  const isCookieAndAlreadyDone = (answer, answerIndex) => (
    answer.isCookie
    && getLocalStorage('cookiesAlreadyCompleted').includes(answerIndex)
  );

  return currentChapter && currentQuestion ? (
    <div className={!answerAlert ? 'green-points-bg' : ''}>
      <section className="questionnaire max-wrapper">
        <h1 className="chapter-name">
          {`${currentChapter} : ${chapterNames[currentChapter]}`}
        </h1>
        {getQuestions(currentChapter) ? (
          <div className="question-wrapper" id="questionWrapper">

            {!answerAlert ? (
              <>
                <h2
                  id={currentQuestion}
                  className="question"
                >
                  {getQweritips(getQuestions(currentChapter)[currentQuestion], currentQuestion)}

                  <ReactTooltip
                    id={`tooltip-${currentQuestion}`}
                    className="app-tooltip"
                    clickable
                    place="bottom"
                    effect="solid"
                  />
                </h2>

                <ul className={`answers
                  ${Object.values(getQuestions(currentChapter)[currentQuestion].answers).length > 3
                  ? 'answers--more' : ''}
                `}
                >
                  {Object.values(getQuestions(currentChapter)[currentQuestion].answers).map((answer, answerIndex) => (
                    <li
                      key={answer.label}
                      className={`
                      answers__item
                      ${selectedAnswer === answerIndex ? 'selected' : ''}
                    `}
                    >
                      <Button
                        label={parse(answer.label)}
                        answer
                        onClick={() => handleSelectAnswer(answerIndex)}
                        disabled={isCookieAndAlreadyDone(answer, answerIndex)}
                      />
                    </li>
                  ))}
                </ul>

                <Button
                  id="nextQuestionButton"
                  label="Valider"
                  arrow
                  onClick={() => handleNextQuestion(false)}
                  className="next-question"
                  disabled={selectedAnswer === null}
                />
              </>
            ) : (
              <>
                <h2 className="question">
                  {answerAlert.label}
                </h2>

                <Button
                  id="nextQuestionButton"
                  label="Question suivante"
                  arrow
                  onClick={() => handleNextQuestion(true)}
                  className="next-question"
                />
              </>
            )}
          </div>
        ) : null}

        {currentQuestion && currentChapter && !answerAlert ? (
          <ProgressBar currentChapter={currentChapter} currentQuestion={+currentQuestion} />
        ) : null}
      </section>
    </div>
  ) : null;
};

Questionnaire.propTypes = {
  currentChapter: PropTypes.number,
  currentQuestion: PropTypes.number,
  setCurrentQuestion: PropTypes.func.isRequired,
};

Questionnaire.defaultProps = {
  currentChapter: null,
  currentQuestion: null,
};

export default Questionnaire;
