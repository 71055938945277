import chapter1 from '../constants/chapter1.json';
import chapter3 from '../constants/chapter3.json';

import form1 from '../constants/forms/form1.json';
import form2 from '../constants/forms/form2.json';
import form3 from '../constants/forms/form3.json';
import form4 from '../constants/forms/form4.json';
import form5 from '../constants/forms/form5.json';
import form6 from '../constants/forms/form6.json';
import form7 from '../constants/forms/form7.json';
import form8 from '../constants/forms/form8.json';
import form9 from '../constants/forms/form9.json';
import form10 from '../constants/forms/form10.json';
import form11 from '../constants/forms/form11.json';
import form12 from '../constants/forms/form12.json';
import form13 from '../constants/forms/form13.json';
import form14 from '../constants/forms/form14.json';
import form15 from '../constants/forms/form15.json';
import form16 from '../constants/forms/form16.json';
import form17 from '../constants/forms/form17.json';
import form18 from '../constants/forms/form18.json';
import form19 from '../constants/forms/form19.json';

const getNumberOfQuestionsByChapter = (chapterId, formId) => {
  switch (chapterId) {
    case 1:
      return Object.values(chapter1.questions).length;
    case 2:
      switch (formId) {
        case 1:
          return Object.values(form1.questions).length;
        case 2:
          return Object.values(form2.questions).length;
        case 3:
          return Object.values(form3.questions).length;
        case 4:
          return Object.values(form4.questions).length;
        case 5:
          return Object.values(form5.questions).length;
        case 6:
          return Object.values(form6.questions).length;
        case 7:
          return Object.values(form7.questions).length;
        case 8:
          return Object.values(form8.questions).length;
        case 9:
          return Object.values(form9.questions).length;
        case 10:
          return Object.values(form10.questions).length;
        case 11:
          return Object.values(form11.questions).length;
        case 12:
          return Object.values(form12.questions).length;
        case 13:
          return Object.values(form13.questions).length;
        case 14:
          return Object.values(form14.questions).length;
        case 15:
          return Object.values(form15.questions).length;
        case 16:
          return Object.values(form16.questions).length;
        case 17:
          return Object.values(form17.questions).length;
        case 18:
          return Object.values(form18.questions).length;
        case 19:
          return Object.values(form19.questions).length;
        default:
          break;
      }
      break;
    case 3:
      return Object.values(chapter3.questions).length;
    default:
      break;
  }

  return 0;
};

export default getNumberOfQuestionsByChapter;
