/* eslint-disable react/jsx-props-no-spreading */

// Utils
import ReactTooltip from 'react-tooltip';

import {
  TransitionGroup,
  CSSTransition,
} from 'react-transition-group';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { useEffect } from 'react';
import getLocalStorage from './utils/getLocalStorage';
import ScrollToTop from './utils/scrollToTop';

// Routes
import routes from './routes';

// Components
import Header from './components/organisms/Header/Header';
import Cookies from './components/organisms/Cookies/Cookies';

function App() {
  useEffect(() => {
    if (process?.env?.REACT_APP_VERSION) {
      localStorage.removeItem(`qweri-module-rgpd-v${process.env.REACT_APP_VERSION - 1}`);
    }
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Header />

      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={{ enter: 300, exit: 300 }}
              classNames="fade"
            >
              <section className="route-section">
                <Switch location={location}>
                  {!getLocalStorage() && location.pathname !== '/' ? (
                    <Redirect to="/" />
                  ) : (
                    routes.map((route) => (
                      <Route key={route.path} {...route} />
                    ))
                  )}
                  <Redirect from="*" to="/" />
                </Switch>
                <Cookies />
              </section>
            </CSSTransition>
          </TransitionGroup>
        )}
      />

      <ReactTooltip className="app-tooltip" clickable place="bottom" effect="solid" />
    </Router>
  );
}

export default App;
