import { isArray } from 'lodash';
import getLocalStorage from '../utils/getLocalStorage';
import setLocalStorage from '../utils/setLocalStorage';

const storeResults = (chapter, question, answer, points, form) => {
  let stringifyAnswer = '';
  if (isArray(answer)) {
    answer.forEach((answ, index) => {
      stringifyAnswer += (index === 0) ? `${answ}` : ` / ${answ}`;
    });
  }

  const updatedResults = getLocalStorage('results');
  updatedResults.push({
    chapter,
    question,
    form,
    answer: stringifyAnswer === '' ? answer : stringifyAnswer,
    points,
  });
  setLocalStorage('results', updatedResults);
};

export default storeResults;
