import getLocalStorage from '../utils/getLocalStorage';

const editScore = (currentChapter, points) => {
  const storage = getLocalStorage();
  const currentScore = storage.score[`chapter_${currentChapter}`];

  storage.score[`chapter_${currentChapter}`] = currentScore + points > 0 ? currentScore + points : 0;
  localStorage.setItem(`qweri-module-rgpd-v${process?.env?.REACT_APP_VERSION}`, JSON.stringify(storage));
};

export default editScore;
