import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

// Components
import Button from '../../components/atoms/Button/Button';

// Constants
import chapterNames from '../../constants/chapterNames';

// Services
import setProgression from '../../services/setProgression';
import setLocalStorage from '../../utils/setLocalStorage';

const NewChapter = () => {
  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);

  const [currentChapter] = useState(+urlParams.get('c'));

  useEffect(() => {
    setLocalStorage('newChapter', currentChapter);
  }, []);

  const handleNextChapter = () => {
    if (currentChapter <= Object.entries(chapterNames).length) {
      setProgression(currentChapter, 1);
      setLocalStorage('newChapter', null);
      history.push(`/chapter${currentChapter}`);
    }
  };

  return currentChapter ? (
    <div className="green-triangle">
      <section className="new-chapter max-wrapper">
        <h1 className="new-chapter__num">
          {`Chapitre ${currentChapter}`}
        </h1>
        <h2 className="new-chapter__title">{chapterNames[currentChapter]}</h2>

        <Button label="C'est parti" arrow onClick={handleNextChapter} />
      </section>
    </div>
  ) : null;
};

export default NewChapter;
