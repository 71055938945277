import getLocalStorage from '../utils/getLocalStorage';

const setProgression = (currentChapter, currentQuestion) => {
  const storage = getLocalStorage();
  if (storage) {
    storage.currentChapter = +currentChapter;
    storage.currentQuestion = +currentQuestion;
    localStorage.setItem(`qweri-module-rgpd-v${process?.env?.REACT_APP_VERSION}`, JSON.stringify(storage));
  }
};

export default setProgression;
